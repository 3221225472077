@import "../../Containers/Variables.scss";
@import "../../Containers/Helpers.scss";

.page-gallery {
  // transition: 0.4s;
  position: relative;
  padding-bottom: 20px;
  padding-top: 20px;
  background: #000;
  box-shadow: 0 3px 6px rgba(#000, 0.54);

  button {
    outline: none;
    border: none;
    background: url("./Images/button/gallery-button.jpg") no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 600px;
    transition: width 0.4s, height 0.4s, font-size 0.4s;
    font-size: 40px;
    cursor: pointer;

    color: #fff;
    text-shadow: 0 2px 2px #666;
    &:hover .gallery-button-darken {
      background: rgba(#000, 0.4);
    }

    &:hover .gallery-mock-button {
      width: 320px;
      height: 320px;
      font-size: 45px;
    }
  }

  .gallery-button-darken {
    height: 100%;
    width: 100%;
    transition: background-color 0.4s;
  }
  .gallery-mock-button {
    width: 300px;
    height: 300px;
    background: url("../Header/Images/treblers-icon-large.png") no-repeat;
    background-size: contain;
    border-radius: 6px;
    margin: 0 auto;
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    transition: width 0.4s, height 0.4s, font-size 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      text-shadow: none;
    }
  }
  img {
    width: 500px;
  }

  // .viewer {
  //   // transition: 0.4s;
  // }

  .page-gallery-darkened-background {
    width: 100vw;
    position: fixed;
    top: 0;
    height: 100vh;
    z-index: 2000;
    background: rgba(#000, 0.8);
    transition: background-color 0.6s;
  }

  .page-gallery-thumbnails {
    justify-content: center;
    margin-top: 30px;
    display: grid;
    grid-gap: 5%;
    grid-template-columns: repeat(auto-fill, 20%);
    align-items: center;
    height: 90vh;
    overflow: auto;
  }

  .gallery-thumbnail {
    cursor: pointer;
    width: 100%;
  }

  @media (max-width: $tablet-breakpoint) {
    .page-gallery-thumbnails {
      margin: 30px;
      grid-gap: 3%;
      grid-template-columns: repeat(auto-fill, 30%);
    }
  }

  @media (max-width: $phone-breakpoint) {
    .page-gallery-thumbnails {
      grid-gap: 3%;
      grid-template-columns: repeat(auto-fill, 45%);
    }
  }
}

@media (max-width: $container-width) {
  .page-gallery {
    width: 100%;
    button {
      height: 300px;

      background-size: contain;

      &:hover .gallery-mock-button {
        width: 106px;
        height: 106px;
        font-size: 22px;
      }
    }

    .gallery-mock-button {
      height: 100px;
      width: 100px;
      span {
        font-size: 20px;
      }
    }
  }
}
