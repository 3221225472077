@import "../../../Containers/Variables.scss";

.page-info {
  text-align: justify;
  padding: 20px 40px;
  border-radius: 10px;
  color: $text-color;
  cursor: pointer;
  transition: background-color 0.4s;
  @media (min-width: 1280px) {
    &:hover {
      background: darken($background-color, 10%);
    }
  }
}

.band-link {
  font-weight: 700;
  transition: color 0.4s;
  color: $text-color;
  &:hover {
    color: lighten($text-color, 20%);
  }
}

@media (max-width: $phone-breakpoint) {
  .page-info {
    padding: 10px;
  }
}
