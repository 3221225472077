@import "./Variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  -webkit-overflow-scrolling: touch;
}

body {
  font-family: "Lato", sans-serif;
  background-color: $background-color;
  overflow-x: hidden;
}

.locked {
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}
