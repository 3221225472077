@import "../../Containers/Helpers.scss";
@import "../../Containers/Variables.scss";

.page-footer {
  background: $background-color;
  padding-top: 30px;
  color: $text-color;

  .footer-container {
    display: flex;
    justify-content: space-between;
  }
  font-size: 20px;

  .footer-email {
    display: flex;
    align-items: center;
    span {
      font-size: 20px;
    }

    a {
      display: flex;
      align-items: center;
    }
  }

  span {
    font-weight: 700;
  }
  a {
    text-decoration: none;
    color: $text-color;
    display: inline-block;
    padding: 0 16px;
    transition: color 0.4s;
    &:hover {
      color: #fff;
    }
  }

  @media (max-width: $tablet-breakpoint) {
    padding: 10px 0;
    font-size: 12px;
    .footer-container {
      flex-direction: column;
      align-items: flex-start;
      width: 250px;
      margin: 0 auto;
    }
    .footer-email {
      span {
        font-size: 12px;
      }
    }
    .footer-icons {
      font-size: 16px;
      a {
        padding-right: 20px;
        padding-left: 0;
      }
    }
    a {
      padding: 5px;
    }
  }

  &.emphasized a {
    color: #fff;
  }

  .copyright-info {
    font-size: 10px;
    display: block;
    text-align: right;
    margin-top: 5px;
    margin-bottom: 0;
  }
}
