@import "../../../Containers/Variables.scss";

.page-logo {
  height: 100%;

  transition: background-color 0.4s;
  cursor: pointer;

  &:hover {
    background: rgba(#fff, 0.4);
  }

  .page-logo-container {
    height: 100px;
    display: flex;
    align-items: center;
  }
  .page-logo-image {
    width: 200px;
    height: 50px;
    background: url("../Images/logo-treblers.png") no-repeat;
    background-size: contain;
    background-position: center center;
  }

  .page-logo-icon {
    width: 60px;
    height: 60px;
    background: url("../Images/logo-icon.png") no-repeat;
    background-size: contain;
    background-position: center center;
    // transition: 0.8s;
  }
}

@media (max-width: $phone-breakpoint) {
  .page-logo {
    .page-logo-container {
      height: 70px;
    }

    .page-logo-image {
      height: 35px;
    }

    .page-logo-icon {
      height: 40px;
    }
  }
}
