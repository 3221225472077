@import "../../../Containers/Variables.scss";

.page-info-flex-container {
  display: flex;
  .page-info-text {
    width: 50%;
  }

  .page-info-images {
    width: 50%;
    padding-left: 20px;
    img {
      width: 100%;
      border-radius: 10px;
      box-shadow: 0px 0px 20px #555;
    }
  }

  @media (max-width: $container-width) {
    flex-direction: column;
    .page-info-text {
      width: 100%;
    }
    .page-info-images {
      width: 100%;
      margin-bottom: 16px;
      padding: 0;
      img {
        margin: 10px 0;
      }
    }
  }
}

.info-video {
  box-shadow: 0px 0px 20px #555;
  border-radius: 10px;
  overflow: hidden;
}

.page-info-images {
  padding-bottom: 20px;
  img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 20px #555;
  }
}
