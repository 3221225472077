@import "../../../../Containers/Variables.scss";
.list-item {
  font-size: 20px;
  height: 100%;
  /* margin: 40px 20px; */
  transition: background-color 0.4s;
  text-align: center;
  cursor: pointer;
  color: #eee;
  font-size: 20px;
  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }
  display: flex;
  align-items: center;
  .scroll-button {
    padding: 20px;
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .list-item {
    height: 100%;
    width: 100%;
    font-size: 1em;
  }
}

@media (max-width: $phone-breakpoint) {
  .list-item {
    height: 40px;
    .scroll-button {
      padding: 0 20px;
    }
  }
}
