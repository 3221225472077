@import "../../Containers/Variables.scss";

.scroll-helper {
  height: 100px;
  position: absolute;
  bottom: 0;
}

@media (max-width: $phone-breakpoint) {
  .scroll-helper {
    height: 50px;
  }
}
