@import "../../Containers/Helpers.scss";
@import "../../Containers/Variables.scss";

.page-info-card {
  position: relative;
  padding-bottom: 20px;
}

.info-container {
  background: $background-color;
  text-align: justify;
  border-radius: 10px;
  color: $text-color;
  cursor: pointer;
  transition: background-color 0.4s;
  margin-top: -20px;
  h2 {
    font-size: 26px;
    margin-bottom: 20px;
  }

  p {
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 1.5;
  }
  span {
    display: inline-block;
    font-weight: 800;
    background-color: rgba(#008a8a, 0.43);
    border-radius: 6px;
    padding: 0 10px;
    box-shadow: 0 1px 3px #555;
  }
  .emphasized {
    background: darken($background-color, 10%);
  }
}

@media (max-width: $phone-breakpoint) {
  .page-info-card {
    h2 {
      font-size: 15px;
    }

    p {
      font-size: 12px;
    }
  }
}
