@import "../../Containers/Helpers.scss";
@import "../../Containers/Variables.scss";

.sticky {
  position: fixed;
  top: 0;
}

.page-header {
  background-color: #000;
  background-position: center;

  width: 100%;
  z-index: 1000;
  box-shadow: 0 3px 6px rgba(#000, 0.54);
}

.page-header-internal {
  display: flex;
  justify-content: space-between;
}

.mock-header {
  height: 100px;
}

@media (max-width: $phone-breakpoint) {
  .mock-header {
    height: 50px;
  }
}
