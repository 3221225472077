@import "../../../Containers/Variables.scss";

.page-info-extended {
  position: relative;
  background: $background-color;
  color: $text-color;
  border-radius: 6px;
  box-shadow: 0px 2px 5px darken(#999, 20%);
  text-align: justify;
  height: 90vh;
  overflow: auto;
  padding: 50px;
  margin-top: 20px;
  h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  p,
  ul {
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 1.5em;
  }
  .info-video {
    display: block;
    margin: 20px auto;
  }

  a {
    color: $text-color;
    font-weight: 700;
    transition: color 0.4s;
    &:hover {
      color: lighten($text-color, 20%);
    }
  }
  .page-info-quote {
    text-align: center;
  }
}

@media (max-width: $phone-breakpoint) {
  .page-info-extended {
    h2 {
      font-size: 15px;
    }

    p,
    ul {
      font-size: 10px;
    }
  }
}
