@import "../../../Containers/Variables.scss";

.menu {
  height: 100%;
  transition: right 0.5s;
  text-align: center;

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: center;
    list-style: none;
    height: 100px;
  }

  a {
    width: 100%;
  }
  .menu-item-wrapper {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .menu {
    height: 420px;
    position: absolute;
    top: 100px;
    right: -400px;
    background-color: #000;
    background-position: center center;
    border-bottom-left-radius: 10px;

    &.menu-show {
      right: 0;
      .line-top {
        transform: translate(-10px, 17px) rotate(225deg);
        width: 118%;
      }

      .line-bottom {
        transform: translate(-10px, -17px) rotate(-225deg);
        width: 118%;
      }

      .line-middle {
        transform: translate(200px, 0);
        background: transparent;
      }
    }

    .hamburger-icon {
      width: 60px;
      height: 60px;
      position: fixed;
      top: 20px;
      right: 20px;

      .hamburger-line {
        width: 100%;
        height: 2px;
        background-color: #eee;
        margin: 15px 0;
        transition: 0.5s;
      }
    }
    ul {
      display: block;
      justify-content: space-between;
      align-items: center;
      align-items: center;
      list-style: none;
      height: auto;
      width: 100%;
    }
  }
}

@media (max-width: $phone-breakpoint) {
  .menu {
    top: 70px;
    height: 280px;

    &.menu-show {
      right: 0;
      .line-top {
        transform: translate(-5px, 8px) rotate(225deg);
        width: 118%;
      }

      .line-bottom {
        transform: translate(-5px, -9px) rotate(-225deg);
        width: 118%;
      }

      .line-middle {
        transform: translate(200px, 0);
        background: transparent;
      }
    }
    .hamburger-icon {
      width: 30px;
      height: 30px;
      position: fixed;
      top: 20px;
      right: 10px;
      .hamburger-line {
        margin: 7px 0;
      }
    }
  }
}
